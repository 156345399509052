<template>
  <div class="input-wrapper">
    <ChopbetSlideUp :isOpen="isSlideUp" @closeSlideup="closeSlideUp">
      <div class="page_container create_pin">
        <div class="close">
          <span>{{ $t("createPin") }}</span>
          <span @click="closeSlideUp()"><CancelIcon /></span>
        </div>

        <div class="input_wrapper">
          <input
            type="tel"
            :placeholder="$t('enterNewPin')"
            :aria-label="$t('enterNewPin')"
            name="pin"
            v-model="pin"
            maxlength="4"
            @keypress="onlyNumbers"
          />
        </div>
        <div class="input_wrapper">
          <input
            type="tel"
            :placeholder="$t('comfirmNewPin')"
            :aria-label="$t('comfirmNewPin')"
            name="confirmPin"
            v-model="confirmPin"
            maxlength="4"
            @keypress="onlyNumbers"
          />
          <label for="">{{ $t("pleaseEnter4DigitPin") }}</label>
        </div>
        <ChopbetButton
          variant="primary"
          @click="createPin"
          :disabled="disabled"
          :loading="loading"
        >
          {{ $t("savePin") }}</ChopbetButton
        >
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import ChopbetSlideUp from "./ChopbetSlideUp.vue";
import ChopbetButton from "./ChopbetButton.vue";
import CancelIcon from "../icons/CancelIcon.vue";
import identity from "../../services/identity";
export default {
  name: "CreatePin",
  components: {
    ChopbetSlideUp,
    ChopbetButton,
    CancelIcon,
  },
  props: {
    isSlideUpOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "deposit",
    },
  },
  data() {
    return {
      myProfile: {},
      pin: "",
      confirmPin: "",
      disabled: true,
      isSlideUp: this.isSlideUpOpen,
      loading: false,
    };
  },
  methods: {
    slideUp() {
      this.isSlideUp = true;
    },
    closeSlideUp() {
      this.isSlideUp = false;
    },
    onlyNumbers(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    validateOtp(event) {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCheck =
        value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
      this.otp = decimalCheck;
    },
    async createPin() {
      this.loading = true;
      if (this.pin !== this.confirmPin) {
        this.setError(`${this.$t("pinDoesNotMatch")}`);
        this.loading = false;
        return;
      }
      try {
        var path = "/pin";
        const res = await identity.post(
          path,
          {
            pin: Number(this.pin),
          },
          {
            headers: {
              "api-key": this.getAuth(),
            },
          }
        );
        if (res.status === 200) {
          this.setSuccess(`${this.$t("pinCreatedSuccessfully")}`);
          this.setProfile({
            ...this.myProfile,
            has_set_pin: true,
          });
          const url =
            this.type === "deposit"
              ? "/deposit/chop-agent"
              : "/withdraw/chop-agent";
          this.$router.push(url);
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
  },
  watch: {
    isSlideUpOpen() {
      this.isSlideUp = true;
    },
    pin() {
      if (this.pin.length === 4 && this.confirmPin.length === 4) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    confirmPin() {
      if (this.pin.length === 4 && this.confirmPin.length === 4) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
};
</script>

<style scoped>
.create_pin {
  padding-bottom: 5rem;
}
.close {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.close span {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}
</style>
